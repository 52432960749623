import React, { useState } from 'react'
import { LamePage } from './LamePage'
import { NewsItem } from './components/NewsItem'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateNews } from './components/UpdateNews';
import { Button, CircularProgress } from '@mui/material';
import { TourItem } from './components/TourItem';
import { UpdateTour } from './components/UpdateTour';
import { TourHeader } from './components/TourHeader';
import { useMediaQuery } from '@mui/material';

export const Tour = () => {
  const { isAuthenticated } = useAuth0();
  const [tour, setTour] = useState([])
  const [addTourDate, setAddTourDate] = useState(false)
  const matches = useMediaQuery('(max-width:400px)');

  const getTour = async () => {
    const res = await axios.get('https://api.lamegenie.com/api/tour')
    setTour(res.data);
    console.log(res.data)
}

const handleAddTourDateClick = (e) => {
  e.preventDefault();
  setAddTourDate(true);
}

const refreshTour = () => {
  getTour();
  setAddTourDate(false);
}

const addTourDateSection = () => {
  if(addTourDate){
    return <UpdateTour refreshTour={refreshTour}/>
  } else {
    return <Button onClick={handleAddTourDateClick} variant="contained" color="primary"> Add Tour Date</Button>
  }
}

const upcomingTourDates = tour.sort((a, b) => Date.parse(a.date) - Date.parse(b.date)).filter((item) => Date.parse(item.date) >= Date.now())
React.useEffect(() => {
  getTour();
  console.log(upcomingTourDates)
}, [])

  return (
    <LamePage otherClass='tour'>
      { tour.length !== 0 ?
      <div>
        <h1>Tour</h1>
        { !matches && <TourHeader /> }
        { upcomingTourDates.length > 0 ?
        upcomingTourDates.map((item, index) => {
          return <TourItem key={index} date={item.date} venue={item.venue} info={item.info} ticketLink={item.ticketLink} id={item._id} refreshTour={refreshTour}/>
        }) : <h1>No upcoming tour dates</h1>
        }
        { isAuthenticated &&
          addTourDateSection()
        }
        </div>
        : 
        <div className='d-flex w-100 justify-content-center align-items-center' style={{height: '50vh'}}>
          <CircularProgress color="secondary" />
        </div>        }
    </LamePage>
  )
}

import React, { useState } from 'react'
import { LamePage } from './LamePage'
import { NewsItem } from './components/NewsItem'
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { UpdateNews } from './components/UpdateNews';
import { Button, CircularProgress } from '@mui/material';
export const News = () => {
  const { isAuthenticated } = useAuth0();
  const [news, setNews] = useState([])
  const [addNews, setAddNews] = useState(false)
  const getNews = async () => {
    const res = await axios.get('https://api.lamegenie.com/api/news')
    setNews(res.data);
    console.log(res.data)
}

const handleAddNewsClick = (e) => {
  e.preventDefault();
  setAddNews(true);
}

const refreshNews = () => {
  getNews();
  setAddNews(false);
}
const addNewsSection = () => {
  if(addNews){
    return <UpdateNews refreshNews={refreshNews}/>
  } else {
    return <Button onClick={handleAddNewsClick} variant="contained" color="primary"> Add News Item</Button>
  }
}
React.useEffect(() => {
    getNews();
}, [])
  return (
    <LamePage otherClass='news'>
      { news.length !== 0 ?
      <div>
        <h1>News</h1>
        { news.length > 0 &&
        news.map((item, index) => {
          return <NewsItem key={index} date={item.date} title={item.title} content={item.text} id={item._id} refreshNews={refreshNews}/>
        })
        }
        </div>
        : 
        <div className='d-flex w-100 justify-content-center align-items-center' style={{height: '50vh'}}>
          <CircularProgress color="secondary" />
        </div>
        }
        { isAuthenticated &&
          addNewsSection()
        }
    </LamePage>
  )
}

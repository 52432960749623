import React from 'react';
import { Spotify } from 'react-spotify-embed';

const MyComponent = () => {
  return (
      <Spotify
        link="https://open.spotify.com/artist/0LTTK6hdRk3ksLfnuppOHm?si=DkdY9IIlRpqV9165ZYmB9A"
        view="coverart"
        theme="black"
        width="100%"
        style={{ background: 'black' }}
      />
  );
};

export default MyComponent;
import React from 'react'
import { LamePage } from './LamePage'
import SpreadShirt from './components/SpreadShirt'
import { faBandcamp, faShirtsinbulk } from '@fortawesome/free-brands-svg-icons'
import { Button, Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const Merch = () => {
  return (
    <LamePage otherClass='merch'>
        <h1>Merch</h1>
        <Row className='m-5'>
          <Col>
            <Button href='https://lamegenie.bandcamp.com/' target='_blank' rel='noopener noreferrer' color='primary' size='lg' block>Bandcamp <FontAwesomeIcon icon={faBandcamp} /></Button>
          </Col>
          <Col>
            <Button href='https://LameGenieVGM.myspreadshop.com/' target='_blank' rel='noopener noreferrer' color='danger' size='lg' block>Spreadshirt <FontAwesomeIcon icon={faShirtsinbulk} /></Button>
          </Col>
        </Row>
    </LamePage>
  )
}

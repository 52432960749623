import React from 'react'
import { Col, Row } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';

export const NewsItem = (props) => {
    const { isAuthenticated } = useAuth0();

    const handleDelete = async() => {
        await axios.delete(`https://api.lamegenie.com/api/news/${props.id}`)
        props.refreshNews();
    }
  return (
    <div className='news-item'>
        <Row>
            <Col>
                <h5>{props.date} - {props.title}</h5>
            </Col>
        </Row>
        <Row>
            <Col>
                { props.img && <img src={props.img} alt={props.imgAlt} /> }
                <p>{props.content}</p>
            </Col>
            { isAuthenticated && 
            <Col>
                <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
            </Col>
            }
        </Row>
    </div>
  )
}

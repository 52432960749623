import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const LoadingBar = ({ progress }) => {
  return (
    <div className='d-flex w-100 justify-content-center align-items-center bg-dark' style={{height: '100vh'}}>
        <LinearProgress className='w-100 loading-bar' variant="determinate" value={progress} />
        <p>Loadin'</p>
    </div>
  );
};

export default LoadingBar;

import React from 'react'
import { LamePage } from './LamePage'
import { motion } from 'framer-motion'
import SpotifyPlayer from './components/SpotifyPlayer'
import { Col, Row, Button } from 'reactstrap'
import { Facebook, Twitter, Instagram, YouTube, Patreon, Loyalty } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faBandcamp } from '@fortawesome/free-brands-svg-icons'

export const Music = () => {
  return (
    <LamePage otherClass='music'>
      <motion.h1
      initial={{ right: 50 }}
      animate={{ right: 0 }}
      exit={{ right: -50 }}
      transition={{ duration: 0.5 }}
    >
    Music
      </motion.h1>

      <SpotifyPlayer />
      <Row className='m-4'>
        <Col>
          <a href='https://open.spotify.com/artist/0LTTK6hdRk3ksLfnuppOHm?si=DkdY9IIlRpqV9165ZYmB9A' target='blank'>
          <Button color='success'>Spotify  <FontAwesomeIcon icon={faSpotify} size='xl'/></Button>
          </a>
        </Col>
        <Col>
        <a href='https://lamegenie.bandcamp.com' target='blank'>
          <Button color='primary'>Bandcamp  <FontAwesomeIcon icon={faBandcamp} size='xl'/></Button>
        </a>
        </Col>
      </Row>
    </LamePage>
  )
}

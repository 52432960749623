import React from 'react'
import { Col, Row } from 'reactstrap'

export const TourHeader = () => {

  return (
    <div className='news-item'>
        <Row>
            <Col>
                <h5>Date</h5>
            </Col>
            <Col>
                <h5>Venue</h5>
            </Col>
            <Col>
                <h5>Show</h5>
            </Col>
            <Col>
                <h5>Tickets</h5>
            </Col>
        </Row>
    </div>
  )
}

import React from 'react'
import { LamePage } from './LamePage'
import ContactForm from './components/ContactForm'
export const Contact = () => {
  return (
    <LamePage otherClass='contact'>
        <h1>Contact</h1>
        <ContactForm />
    </LamePage>
  )
}

import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Email } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { LamePage } from './LamePage';
import { Row, Col } from 'reactstrap';
import taco from './img/taco1.jpg'
export const About = () => {

  const aboutText = `Formed in 2013, Lame Genie has made a name for themselves through their polished, heavy video game covers and memorable live shows with sync'd visuals and projections. Having recorded over 20 albums in their 10 year span, this is a band that doesn't plan on stopping anytime soon.
\n \n
  They have been featured at many notable conventions, namely:
\n \n
Super MagFest (MD) (2019 / 2020) \n
PAX East (MA) (2016 / 2017 / 2018 / 2019) \n
Too Many Games (PA) (2019) \n
Bit Gen Festival (MD) (2018 / 2020) \n
Retro Games Con (NY) (2018 / 2019) \n
ReplayFX (PA) (2019) \n
NEON Festival (RI) (2018 / 2020) \n
CTGamerCon (CT) (2016 / 2019) \n
Retro World Expo (CT) (2016 / 2019) \n
+ many more.
\n \n
Their live shows are an experience you won't soon forget
\n \n`

const lines = aboutText.split('\n');

const renderedLines = lines.map((line, index) => (
  <p key={index}>{line}</p>
));

  return (
    <LamePage otherClass='about'>
      <h1>About</h1>
      <hr style={{ color: 'white'}}/>
      <p className='text-center'>Lame Genie is the premier video game music cover band (heavy rock/metal) in Providence, RI.</p>
      <br/>
      <Row>
        <Col>
          <img src={taco} className='taco'/>
        </Col>
        <Col>
        {renderedLines}
        <p>Booking: <a href='mailto:lamegenieband@gmail.com'>lamegenieband@gmail.com</a></p>
        </Col>
      </Row>

    </LamePage>
  )
}

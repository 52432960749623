import React, { useState } from 'react'
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';

export const UpdateNews = (props) => {
  const [formData, setFormData] = useState({
    title: '',
    date: '',
    content: '',
    firstName: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (formData.title === '' || formData.date === '' || formData.content === '') {
      alert('Fill it all out, idiot.')
      return;
    }

    const res = await axios.post('https://api.lamegenie.com/api/news', {
      date: formData.date,
      title: formData.title,
      text: formData.content
    })
    props.refreshNews();
  }
  const getNews = async () => {
        const res = await axios.get('https://api.lamegenie.com/api/news')
        console.log(res.data)
    }

    React.useEffect(() => {
        getNews();
    }, [])
  return (
    <div className='m-4 news-item'>
      <h5>Add News Item</h5>
      <form onSubmit={handleSubmit} className='contact-form'>
      <TextField label="First Name"
        name='firstName'
        sx={{ display: 'none' }}
        onChange={handleChange} />
      <TextField
        label="Date"
        variant="filled"
        name="date"
        value={formData.date}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Title"
        variant="filled"
        name="title"
        value={formData.title}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Content"
        variant="filled"
        name="content"
        value={formData.content}
        onChange={handleChange}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <Button type="submit" variant="contained" color="primary">
        Add News Item
      </Button>
    </form>
  </div>
  )
}

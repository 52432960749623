import React from 'react';
import './App.css';
import './css/bootstrap.css';
import './css/mdb.min.css';
import './css/style.css';
import Navbar from './components/Navbar';
import { Routes, Route } from 'react-router-dom'; // Use HashRouter
import { Home } from './Home';
import { About } from './About';
import { AnimatePresence, motion } from 'framer-motion';
import { Music } from './Music';
import { Merch } from './Merch';
import { Band } from './Band';
import { News } from './News';
import { Tour } from './Tour';
import { Videos } from './Videos';
import { Contact } from './Contact';
import PreloadAssets from './components/PreloadAssets';
import LoadingBar from './components/LoadingBar';
import Dashboard from './Dashboard';
const assetUrls = [
  require('./img/taco1.jpg'),
  require('./img/lggood.jpg'),
  require('./img/lgpromo.png'),
  require('./img/logo.png'),
  require('./img/mag.jpg'),
  require('./img/mag2.jpg'),
  require('./img/mag3.jpg'),
  require('./img/tmg.jpg'),
  require('./img/tacofloor.jpg'),
  require('./img/tacocheers.jpg'),
  require('./img/jeff.png'),
  require('./img/kyle.png'),
  require('./img/mike.png'),
  // Add more asset URLs
];
function App() {
  return (
      <div className="App">
        <div>
        <PreloadAssets assets={assetUrls}>

              <div>
            <Navbar />
                <div>
                  <AnimatePresence mode="wait">
                    <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path='/music' element={<Music />} />
                      <Route path='/videos' element={<Videos />} />
                      <Route path='/merch' element={<Merch />} />
                      <Route path='/news' element={<News />} />
                      <Route path='/tour' element={<Tour />} />
                      <Route path='/band' element={<Band />} />
                      <Route path='/contact' element={<Contact />} />
                      <Route path='/login' exact element={<Dashboard />} />
                    </Routes>
                  </AnimatePresence>
                </div>
              </div>

          </PreloadAssets>

        </div>
      </div>
  );
}

export default App;

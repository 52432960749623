import React from 'react'
import { LamePage } from './LamePage';
import Button from '@mui/material/Button';
import { Email } from '@mui/icons-material';
export const Home = () => {
  return (
    <LamePage title={true} otherClass='main'>
      <h1>Lame Genie</h1>
      <p className='text-center'>Video Game Cover Band // Providence, RI</p>
      <hr />
      <p className='text-center'>
        <a href="mailto:lamegenieband@gmail.com" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" startIcon={<Email />}>
            Book Now
          </Button>
        </a>
      </p>
    </LamePage>
  )
}

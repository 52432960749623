import React from 'react'
import { LamePage } from './LamePage'
export const Videos = () => {
  return (
    <LamePage otherClass='videos'>
        <h1>Videos</h1>
        <iframe width="100%" height="700" src="https://www.youtube.com/embed/videoseries?list=PLLmyi43A7syqQx001PZXoCIH6k_qCxG7s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </LamePage>
  )
}

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LamePage } from './LamePage';
import { Button } from '@mui/material';
function Dashboard() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return (
    <LamePage>
      {isAuthenticated ? (
        <div>
          <h1>Welcome, {user.name}!</h1>
          <Button variant="contained" color='secondary' onClick={() => logout()}>Logout</Button>
        </div>
      ) : (
        <Button variant="contained" color='primary' onClick={() => loginWithRedirect()}>Login</Button>
      )}
    </LamePage>
  );
}

export default Dashboard;
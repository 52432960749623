import React, { useState, useRef } from 'react';
import { TextField, Button, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import emailjs from '@emailjs/browser';
const ContactForm = () => {
  const form = useRef();
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [open, setOpen] = React.useState(false);
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
    firstName: ''
  });

  const sendEmail = (e) => {
    e.preventDefault();

    if (formData.firstName === '' && !isFormEmpty) {
    emailjs.sendForm(serviceId, templateId, form.current, publicKey)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    setIsFormEmpty(true);
    } else {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    
    // Check if any field is empty
    setIsFormEmpty(formData.name === '' || formData.email === '' || formData.message === '');
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(e);

    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className='contact-form'>
            <Snackbar
            anchorOrigin={{ vertical:'center', horizontal:'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={() => {}}
            message={isFormEmpty ? "Please fill out all fields" : "Message sent! The boys will get back to you soon."}
            action={() => {}}
            color='red'
            ContentProps={{
              classes: {
                root: isFormEmpty ? 'bg-danger' : 'bg-success'
              }
            }}
            className='snackbar'
          />
      <TextField label="First Name"
        name='firstName'
        sx={{ display: 'none' }}
        onChange={handleChange} />
      <TextField
        label="Name"
        variant="filled"
        name="user_name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Email"
        variant="filled"
        name="user_email"
        onChange={handleChange}
        value={formData.email}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Message"
        variant="filled"
        name="message"
        onChange={handleChange}
        value={formData.message}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <Button type="submit" variant="contained" color="primary">
        Contact
      </Button>
    </form>
  );
};

export default ContactForm;
